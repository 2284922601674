<template>
  <div class="content md">
    <system-admin type="core"></system-admin>
  </div>
</template>

<script>
import SystemAdmin from "../../layouts/SystemAdmin";

export default {
  name: "Auth",
  components: {
    SystemAdmin,
  },
};
</script>

<style lang="less" scoped></style>
